import React from 'react';

const IconLogo = () => (
<svg width="91px" height="104px" viewBox="0 0 91 104" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <title>Logo</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M47.0391323,2.91994115 L87.5391323,27.1268377 C88.445218,27.6684062 89,28.6463262 89,29.7019244 L89,75.2740009 C89,76.3425524 88.4316318,77.3304466 87.5078614,77.8675225 L47.4906777,101.133327 C46.5676913,101.669947 45.4290586,101.675601 44.5007881,101.148175 L3.51797178,77.862484 C2.5796547,77.3293493 2,76.3333138 2,75.2541143 L2,29.7019244 C2,28.6463262 2.55478202,27.6684062 3.46086769,27.1268377 L43.9608677,2.91994115 C44.9087511,2.35339014 46.0912489,2.35339014 47.0391323,2.91994115 Z" id="Polygon" stroke="#64FFDA" strokeWidth="4" fill="#0A192F"></path>
        <text id="R" fontFamily="ProductSans-Bold, Product Sans" fontSize="41" fontWeight="bold" fill="#64FFDA">
            <tspan x="33.29" y="67">R</tspan>
        </text>
    </g>
</svg>      
);

export default IconLogo;
