import React from 'react';

const IconSpotify = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg" role="img"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        viewBox="0 0 22 22">
        <g id="x-circle" transform="translate(-1 -1)">
            <circle id="Ellipse_1" data-name="Ellipse 1" cx="10" cy="10" r="10" transform="translate(2 2)" strokeWidth="2" stroke="#a8b3d1" strokeLinecap="round" strokeLinejoin="round" fill="none" />
            <path id="Path_2" data-name="Path 2" d="M10.586.8A17.48,17.48,0,0,0,5.875-.476a18.618,18.618,0,0,0-4.88.259" transform="translate(6 12)" fill="none" stroke="#a8b3d1" strokeLinecap="round" strokeWidth="1.7" />
            <path id="Path_3" data-name="Path 3" d="M9.175.838A10.916,10.916,0,0,0,5.869-.071,22.69,22.69,0,0,0,1.608.07" transform="translate(6 15)" fill="none" stroke="#a8b3d1" strokeLinecap="round" strokeWidth="1.5" />
            <path id="Path_1" data-name="Path 1" d="M.173.284a25.061,25.061,0,0,1,6.575-.3,16.211,16.211,0,0,1,5.64,1.575" transform="translate(6 8)" fill="none" stroke="#a8b3d1" strokeLinecap="round" strokeWidth="1.9" />
        </g>
    </svg>
);

export default IconSpotify;
